import React, { useContext, useEffect } from 'react'
import Notify from 'tools/Notify'
import Footer from './Footer'
import NavBar from './NavBar'
import { useHistory } from 'react-router-dom'
import GlobalContext from 'reducer/global'
import { AUTHX_ACTIONS } from 'utils/authx'
import { R_GLOBAL } from 'reducer/global'
import config from 'constants/config'
import { bar, menu } from 'site/wst/Root/NavBar/config'

function Root({ children }) {
  const [
    {
      authx: { redirect },
      nav
    },
    dispatch
  ] = useContext(GlobalContext)
  const history = useHistory()

  useEffect(() => {
    console.log('updating nav menus', { menu, bar })
    dispatch({ type: R_GLOBAL.MERGE, value: { nav: { menu, bar } } })
  }, [dispatch])
  console.log('nav menus', { nav, config })

  // authx redirect to signin
  useEffect(() => {
    if (redirect) {
      history.replace('/signout')
      dispatch({ type: AUTHX_ACTIONS.ERROR_CLEAR })
    }
  }, [redirect, history, dispatch])

  return (
    <>
      <div className="body">
        {children}
        <NavBar />
      </div>
      <Footer />
      <Notify />
    </>
  )
}

export default Root
