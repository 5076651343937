// import React from 'react'
import startup from 'startup'
import Router from 'site/wst/Router'
import SiteLogo from 'site/wst/SiteLogo'
import Root from 'site/wst/Root'

startup(
  {
    name: 'Wyrmstone',
    name_a: 'a Wyrmstone',
    name_legal: 'Protos, LLC',
    sitelogo: SiteLogo
  },
  Router,
  { Root }
)
