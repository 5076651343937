import React from 'react'
import config from 'constants/config'

// theme-bg could be put in here, but it looks odd
function Footer(props) {
  return (
    <div className={`footer pa3`}>
      <div className="flex-center flex-column mt5 pa2 b mb4">
        <div className="flex-center gray mb4 mt4 f2">
          <div className="f2">
            <a
              href="https://revenant.studio/legal/"
              target="_blank"
              rel="noopener noreferrer"
              className="flex-items navlink"
            >
              {config.name} &copy; {new Date().getFullYear()}, &trade;{' '}
              {config.name_legal}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
