import React, { useContext } from 'react'
import GlobalContext from 'reducer/global'
import { NavLink } from 'tools/Links'
import { MenuList, MenuButton, MenuItem } from 'react-menu-list'
import style from './index.module.scss'
import { gazmenu } from './config'

function NavItem({
  icon = undefined,
  to,
  children,
  menu = undefined,
  className = 'ph2 pv1 hover-hilite navlink br2',
  Linker = NavLink
}) {
  return (
    <Linker to={to} className={className}>
      <NavLabel icon={icon} menu={menu}>
        {children}
      </NavLabel>
    </Linker>
  )
}

function NavLabel({ icon = undefined, children, menu = undefined }) {
  return (
    <div className="flex flex-column items-center fw6 relative">
      {icon && <i className={`fas fa-${icon} mb1 f4`} />}
      <div className="flex-items">
        {children}
        {menu && <i className="ml1 fas fa-chevron-down f1" />}
      </div>
    </div>
  )
}

export function NavBar() {
  const [
    {
      nav: { bar, menu },
      saving,
      page,
      user
    }
  ] = useContext(GlobalContext)
  let title = page.title
  if (title === 'Wyrmstone') {
    title = ''
  }
  return (
    <>
      <div className={`navbar ${style.navbar} ma0 pa1 flex items-center gh2`}>
        <NavLink
          to="/"
          className={`navlink ${style.navlink} hover-hilite ml3 mr3 f4`}
        >
          <img
            src="/codex/assets/wst/wyrmstone-logo-600.webp"
            alt="Wyrmstone"
            style={{ height: '2rem' }}
            className="db"
          />
        </NavLink>
        <SubMenu menu={gazmenu}>
          <NavLabel menu={true}>Gazetteer</NavLabel>
        </SubMenu>
        <div className="f5 mh-auto ml5 b">{title}</div>
        {saving ? <div className="ml3 gray throb f2">Saving...</div> : null}
        {bar.map((i, x) => (
          <BarElement key={i.label} elem={i} user={user} />
        ))}
        {user.authStatus === 'unknown' ? (
          <NavItem to="/signon">Sign In</NavItem>
        ) : (
          <SubMenu menu={menu}>
            <NavLabel menu={true}>Me</NavLabel>
          </SubMenu>
        )}
      </div>
    </>
  )
}

function SubMenu({ menu, children, className = '' }) {
  const [{ user }] = useContext(GlobalContext)

  return (
    <MenuButton
      menu={
        <div className="pv1 pv2-m pv2-l white mt1 navbar-bg menu">
          <MenuList>
            {menu.map((i) => {
              if (i.authz && !user.can(i.authz)) {
                return null
              }
              if (i.divider) {
                return <div key={i.id} className="bt b--gray" />
              } else {
                return (
                  <MenuItem className="ph3 pv2 hover-hilite">
                    <NavItem key={i.label} to={i.to} className="">
                      {i.label}
                    </NavItem>
                  </MenuItem>
                )
              }
            })}
          </MenuList>
        </div>
      }
      className={`navlink ${style.navlink} clear relative hover-hilite ${className}`}
    >
      {children}
    </MenuButton>
  )
}

function BarElement({ elem, user }) {
  if (elem.authz && !user.can(elem.authz)) {
    return null
  }
  return (
    <NavItem to={elem.to} Linker={elem.Linker || NavLink} icon={elem.icon}>
      {elem.label}
    </NavItem>
  )
}

export default NavBar
