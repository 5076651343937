import paths from 'constants/paths'
import { ExtLink } from 'tools/Links'

export const gazmenu = [
  {
    to: `${paths.pageView}WST/gaz-index`,
    label: 'Introduction',
    className: 'item'
  },
  {
    to: `${paths.pageView}WST/gaz-worlds`,
    label: 'Worlds',
    className: 'item'
  },
  {
    to: `${paths.pageView}WST/gaz-species`,
    label: 'Species',
    className: 'item'
  },
  {
    to: `${paths.pageView}WST/gaz-eternals`,
    label: 'Eternals',
    className: 'item'
  },
  {
    to: `${paths.pageView}WST/gaz-power`,
    label: 'Political Powers',
    className: 'item'
  }
]

export const bar = [
  {
    to: 'https://revenant.studio',
    label: 'Studio',
    Linker: ExtLink
  },
  {
    to: 'https://revenant.studio/social',
    label: 'Social',
    Linker: ExtLink
  }
]

export const menu = [
  {
    to: paths.preferences,
    label: 'Preferences'
  },
  {
    divider: true,
    id: 'admin-div',
    authz: 'admin_user'
  },
  {
    to: paths.pageView,
    label: 'Docs',
    icon: 'file',
    authz: 'doc_admin'
  },
  {
    to: paths.tableView,
    label: 'Tables',
    icon: 'table',
    authz: 'doc_admin'
  },
  {
    to: paths.admSummary,
    label: 'Admin',
    // todo: flip to mentor action
    authz: 'admin_user'
  },
  {
    to: paths.story,
    label: 'Stories',
    icon: 'lightbulb',
    authz: 'admin_user'
  },
  {
    divider: true,
    id: 'signout-div'
  },
  {
    to: paths.signout,
    label: 'Sign Out'
  }
]
